exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-addmission-js": () => import("./../../../src/pages/addmission.js" /* webpackChunkName: "component---src-pages-addmission-js" */),
  "component---src-pages-addmission-preview-js": () => import("./../../../src/pages/addmission-preview.js" /* webpackChunkName: "component---src-pages-addmission-preview-js" */),
  "component---src-pages-adverticement-js": () => import("./../../../src/pages/adverticement.js" /* webpackChunkName: "component---src-pages-adverticement-js" */),
  "component---src-pages-all-notification-js": () => import("./../../../src/pages/all_notification.js" /* webpackChunkName: "component---src-pages-all-notification-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-department-js": () => import("./../../../src/pages/department.js" /* webpackChunkName: "component---src-pages-department-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-doctor-js": () => import("./../../../src/pages/doctor.js" /* webpackChunkName: "component---src-pages-doctor-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/Gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latestnotification-js": () => import("./../../../src/pages/latestnotification.js" /* webpackChunkName: "component---src-pages-latestnotification-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-rating-js": () => import("./../../../src/pages/rating.js" /* webpackChunkName: "component---src-pages-rating-js" */),
  "component---src-pages-rating-more-js": () => import("./../../../src/pages/rating_more.js" /* webpackChunkName: "component---src-pages-rating-more-js" */),
  "component---src-pages-rating-view-js": () => import("./../../../src/pages/rating_view.js" /* webpackChunkName: "component---src-pages-rating-view-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */)
}

